import {
  Header,
  Footer,
  FooterGER
} from '@components'
import { Campaign } from '@utils'
import { IMAGES } from "@assets"
import {
  PAGE_TITLE_GER,
  PAGE_TITLE_NORMAL
} from '@constants'
import Vue from 'vue'

export default {
  components: {
    Header,
    Footer,
    FooterGER
  },
  data() {
    return {
      campaignSettings: {},
      isGreatEasternCampaign: false,
      IMAGES,
    }
  },
  props: ['IpBlock'],
  computed: {
    createSetAppContainerStyles() {
      if (this.campaignSettings) {
        const objStyles = {
          '--app-container-bg-color': this.campaignSettings.preset_bg_color,
          '--app-container-text-color':
                this.campaignSettings.preset_text_color,
          '--app-container-font-family': Campaign.getLanguage().font_type
        }
        this.$setAppStyles(objStyles)
      }
    }
  },
  created() {
    // Get campaign settings from session storage
    const isCampaign = Campaign.getCampaignSettings()
    isCampaign ? this.campaignSettings = isCampaign : null

    // Check if campaign is Great Eastern
    this.isGreatEasternCampaign = Campaign.isGreatEasternCampaign();

    // Set title for page
    let pageTitle = PAGE_TITLE_NORMAL;
    if (this.isGreatEasternCampaign) {
      pageTitle = PAGE_TITLE_GER;
    }
    Vue.nextTick(() => {
      document.title = pageTitle;
    });
  }
}
